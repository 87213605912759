<template>
  <div class="container">
    <img class="logo" src="../assets/images/logo.png" />
    <h3 class="wallet-name">融e钱包</h3>
    <div class="buttons">
      <span class="button" @click="handleDownload">下载</span>
      <span class="tip">预计1分钟内完成下载</span>
    </div>
    <SharePop :show="showPop" @change="handlePopChange" />
  </div>
</template>

<script>
import SharePop from "@/components/SharePop";
import * as API_Common from "@/api/common.js";

export default {
  name: "Wallet",
  components: {
    SharePop,
  },
  data() {
    return {
      url: "",
      channel: this.$route.query.source,
      showPop: false,
    };
  },
  created() {
    this.getDownloadUrl();
  },
  methods: {
    getDownloadUrl() {
      API_Common.getDownloadUrl(this.channel).then((res) => {
        if (res.code === 200) {
          this.url = res.data.url;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    handleDownload() {
      let isWeixin = this.isWeixin();
      if (isWeixin) {
        this.showPop = true;
      } else {
        window.location.href = this.url;
      }
    },
    handlePopChange(show) {
      this.showPop = show;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 5.18rem 0.36rem 0;
  height: 100vh;
  overflow: auto;
  background: url("../assets/images/wallet_bg.png") no-repeat center center;
  background-size: cover;
}
.logo {
  width: 1.92rem;
  height: 1.92rem;
  margin: 0 auto;
  display: block;
}
.wallet-name {
  font-size: 0.36rem;
  font-weight: 600;
  color: #3a3a3a;
  text-align: center;
  margin-bottom: 0.72rem;
}
.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.36rem;
  font-weight: 500;
  color: #ffffff;
}
.button {
  padding: 0.16rem 0.78rem;
  background: #f26946;
  border-radius: 0.08rem;
  margin-bottom: 0.3rem;
}
.tip {
  font-size: 0.28rem;
  margin-top: 2.3rem;
}
</style>
